#billing {

    .sectionPrimary {
        background-image: url('../../assets/bg_princ.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 15rem;
        font-family: 'Poppins', sans-serif;

        .titleProp {
            font-size: 4rem;
            padding-bottom: 5rem;

            @media (max-width:1024px) {
                & {
                    font-size: 2.6rem;
                    line-height: 2.5rem;
                    padding-bottom: 0;

                }
            }

            @media (max-width:667px) {
                & {
                    font-size: 1.5rem;
                    line-height: 2rem;
                }
            }


        }

    }

    .sectionSecondary,
    .sectionTertiary {
        .titleProp {
            font-size: 3.3rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0;
            border-radius: 0;

            @media (max-width:1024px) {
                & {
                    font-size: 2.6rem;
                    line-height: 2.5rem;
                    margin-bottom: 20px;
                }
            }

            .text {

                img {
                    width: 150px;
                    margin-bottom: 15px;
                }


            }

            .btn-title {
                background-color: var(--skyBlue);
                padding: 2.5rem 3.3rem;

                img {
                    width: 30px;
                    height: 100%;

                }
            }

        }

        .is-grouped {

            margin-top: 2rem;

            .button {
                border-radius: 0;
                padding-left: 3rem;
                padding-right: 3rem;

                @media (max-width:667px) {
                    & {
                        padding: 0.50rem;
                    }
                }
            }
        }




    }

    .sectionTertiary {
        .table {
            text-align: center;
            border: 1px solid #dbdbdb !important;

            thead {
                background-color: #0157a0 !important;

                th {
                    color: white !important;
                }
            }

            td {
                color: #0157a0 !important;
                border-left: none !important;
                padding: 1rem;

                i {
                    font-size: 1.2rem;
                    margin-left: 3px;
                }
            }

            .bg-lig {
                background-color: #3e8ed030 !important;
            }

            .bg-dark {
                background-color: #3e8ed0d4;
            }


        }

        button {
            border-radius: 0;
            border: none;
            padding-left: 3rem;
            padding-right: 3rem;
            margin-top: 3rem;
            background: rgb(2, 88, 160);
            background: linear-gradient(90deg, rgba(0, 144, 255, 1) 0%, rgba(2, 88, 160, 1) 55%);
            color: #ffffff;
        }

    }

    /*   Tablet y Laptop*/

    @media (max-width:1024px) {
        .titleProp {
            font-size: 2.6rem;
            line-height: 2.5rem;
            margin-bottom: 20px;


        }

        .sectionPrimary {
            padding-top: 10rem;

            .column p {
                padding-right: 4rem;
            }

        }


        .sectionSecondary {
            #containerPosts {
                margin-top: 0;
            }

            #btnPost .date {
                display: flex;
                justify-content: space-between;
            }

            #btnPost .date .button {
                margin-right: 0px;
                display: flex;
                justify-content: space-between;
            }
        }




    }

    /* 
Mobile */
    @media (max-width:667px) {

        .sectionPrimary {
            padding-top: 6rem;
            margin-bottom: 0rem;
            text-align: center;
            background-image: none;
            background-color: var(--skyBlue) !important;




        }

        .sectionSecondary,
        .sectionTertiary {
            .titleProp {
                font-size: 1.5rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-radius: 0;
                padding: 0;
                line-height: 2rem;
                top: -20px;

                span {
                    padding-left: 0;
                }

                .btn-title {
                    height: auto;
                    padding: 1rem;

                    img {
                        width: 20px;
                    }
                }

                .text {
                    padding-left: 0rem;

                    img {
                        width: 46px;
                        margin-bottom: 4px;
                    }
                }
            }

            .subtitle {
                font-size: 1rem;
            }

            .button {
                border-radius: 0;
                padding-left: 0.75rem;
                padding-right: 0.75rem;
                margin-top: 1.5rem;
                font-size: 0.75rem;

            }

        }

    }





}