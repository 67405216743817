.modal-card-foot,
.modal-card-head.activity {
    border-radius: 0 !important;
}

.modal-card-head.activity {
    background-image: url('../../../assets/bg_modal.png');
    background-size: cover;
    background-repeat: no-repeat;
    display: -webkit-inline-box;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: 'Poppins', sans-serif;

    p {
        font-family: 'Poppins', sans-serif;
        font-size: 1.7rem;
        font-weight: 600;
        line-height: 1.2rem;
        padding: 0.75rem 1.5rem;
        -webkit-text-stroke: 1px #FFFFFF;
        color: transparent;
    }

    h1 {
        font-size: 2rem;
        padding-left: 1rem;
        font-weight: 500;
        line-height: 2.2rem;
    }

}

.modal-card-body {

    h2 {
        font-size: 1.3rem;

    }

    .is-7 .button {
        border: solid 1px #0157a0;
    }

    .btn-y {
        border: solid 1px #F6C10D;
        font-size: 0.60rem;
        padding: 5px 8px;
    }

}

.modal-card-foot {
    border-top: 0;
    background-color: #ffffff;
    display: block !important;
    text-align: center !important;
    .blue {
        border: solid 1px #0157a0;
    }

}

.modal-background {
    background-color: var(--blue);
}

.button {
    border-radius: 0;
}

.modal-close {

    background-color: var(--blue);
}

.modal-card-body {
    select {
        width: 100%;
        justify-content: center;
        background-color: white;
        border: 1px solid #0157a0;
        font-size: 1rem;
        outline: none;
        color: #0157a0;
        padding-left: 10px;
        min-height: 30px;
    }

    input {
        width: 100%;
        justify-content: center;
        background-color: white;
        border: 1px solid #0157a0;
        font-size: 1rem;
        outline: none;
        color: #0157a0;
        padding-left: 10px;
        min-height: 30px;
    }
    @media screen and (max-width: 768px) {
        .mobile-hidden {
            display: none;
          }
        }
    }

    .relative-card {
        position: relative !important;
        min-height: 90vh !important
    }

    .modal-card-body {
        overflow: hidden;
    }
