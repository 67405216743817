
@import "https://cdn.jsdelivr.net/npm/bulma@0.9.3/css/bulma.min.css";
@import "https://pro.fontawesome.com/releases/v5.10.0/css/all.css";
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
 

:root {
  --blue: #0157a0;
  --yelow: #F5C10D;
  --skyBlue: #0080FF;
  --gray: #5D677F;
  --white: #FFFFFF;
}

.blue {
  color: #0157a0;
}

.yellow {
  color: #F6C10D;
}

.skyBlue {
  color: #0080FF !important;
}

.gray {
  color: #5D677F !important;
}

.white {
  color: #FFFFFF !important;
}

.transparent {
  color: transparent;
}

/* ##################### COLOR BACKGROUNS ######################### */

.bg-blue {
  background-color: #0157a0 !important;
}

.bg-yelow {
  background-color: #F6C10D;
}

.bg-skyBlue {
  background-color: #0080FF !important;
}

.bg-gray {
  background-color: #5D677F;
}

.bg-white {
  background-color: #FFFFFF;
}

.bg-transparent {
  background-color: transparent;
}

.border {
  border: solid red 2px;
}

/* .section {
  padding: 3rem 2rem;
} */
.titleProp {
  line-height: 4.5rem;
}

.title-border-white {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  -webkit-text-stroke: 2px #FFFFFF;
  color: transparent;
}

.title-border-blue {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  -webkit-text-stroke: 2px #0157a0;
  color: transparent;
}

.title-bold-white {
  font-family: 'Poppins', sans-serif;
  color: #FFFFFF;
  font-weight: 800;
  text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
}

.title-bold-blue {
  font-family: 'Poppins', sans-serif;
  color: #0157a0;
  font-weight: 800;
  text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
}

.textLineBlue {
  background: linear-gradient(0deg, #0080FF 18px, transparent 7px) 0 1.1em;

  @media (max-width:550px) {
      & {
          background: linear-gradient(0deg, #0080FF 7px, transparent 7px) 0 1.1em;
      }
  }

}

.textLineYelow {
  background: linear-gradient(0deg, #F6C10D 15px, transparent 7px) 0 1.1em;

  @media (max-width:550px) {
      & {
          background: linear-gradient(0deg, #F6C10D 7px, transparent 7px) 0 1.1em;
      }
  }
}

.textLineBlue2 {
  background: linear-gradient(0deg, #0157a0 15px, transparent 7px) 0 1.1em;

  @media (max-width:550px) {
      & {
          background: linear-gradient(0deg, #0157a0 7px, transparent 7px) 0 1.1em;
      }
  }
}

.textLineBlue3 {
  background: linear-gradient(0deg, #0080FF 15px, transparent 7px) 0 1.1em;

  @media (max-width:550px) {
      & {
          background: linear-gradient(0deg, #0080FF 7px, transparent 7px) 0 1.1em;
      }
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
    font-family: 'NotoSans';
    src: url('./assets/NotoSans-Regular.ttf');
}
