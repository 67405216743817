

#certificates,
#activities {
    #titleMyActivities{
        font-size: 70px;
    }
    >div:first-child{
        background-repeat: repeat;
    background-size: cover;
        background-image: url('../../assets/bg_pricipal_1-100.jpg');
        height: 600px;
        display: flex;
        justify-content: center;
        align-items: center;
        >div:first-child{
            height: 710px;
            width: 50%;
        }
        >div:last-child{
            height: 710px;
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 8%;
            >div{
                >img{
                    width: 10%;
                    margin-top: 15%
                }
            }
        }
    }
    #sectionPrimary {

        .is-6 {
            display: flex;
            flex-direction: column;
            padding-left: 2rem;
        }

        img {
            width: 40px;
            margin-left: 1rem;
        }

        #btn-more .button,
        #btnPost .button {
            font-size: 3rem;
            margin-bottom: 3rem;
            margin-top: 8rem;
            line-height: 2.8rem;
        }

        .titleProp {
            font-size: 4rem;
            margin-bottom: 3rem;
            margin-top: 15rem;
            line-height: 3.5rem;
        }
    }

    #sectionSecondary {
        background-color: var(--white);

        .titleProp {
            font-size: 2.5rem;
            line-height: 2.5rem;
            padding-bottom: 2rem;

            img {
                width: 50px;
                margin-bottom: 11px;
                margin-left: 1rem
            }
        }

        article {
            .cont {
                box-shadow: 0px 2px 4px 3px rgba(0, 0, 0, 0.19);
                -webkit-box-shadow: 0px 2px 4px 3px rgba(0, 0, 0, 0.19);
                -moz-box-shadow: 0px 2px 4px 3px rgba(0, 0, 0, 0.19);
                border-radius: 0;
                margin: 1rem;
            }

            h4 {
                display: flex;
                justify-content: space-between;
                font-weight: 600;
                padding-top: 1.5rem;

            }
        }

        .date {
            .button {
                background-color: transparent;
                margin-right: 2rem;
                border: none !important;
            }

            i {
                margin-right: 8px;
                font-size: 1.3rem;
            }
        }

        .button {
            border-radius: 0 !important;
            font-weight: 300;
            border: solid 1px #0080FF;

        }

        h4 {
            font-weight: 600;
            color: var(blue);
            margin-bottom: 0.50rem;
        }
    }

}

#certificates {

    #sectionPrimary {
        background-image: url('../../assets/bg_pricipal_1-100.jpg');
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 14rem;
        font-family: 'Poppins', sans-serif;

        @media (max-width:550px) {
            & {
                background-image: none;
                background-color: var(--gray);
                padding-top: 5rem;
            }
        }
    }
}

#activities {


    #sectionPrimary {
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 14rem;
        font-family: 'Poppins', sans-serif;

        @media (max-width:550px) {
            & {
                background-image: none;
                background-color: var(--blue);
                padding-top: 5rem;
            }
        }

    }
}


#certificates #sectionPrimary #btn-more .button,
#certificates #btnPost .button {
    border-radius: 0;
    padding-left: 3rem;
    padding-right: 3rem;
    margin-top: 5rem;
}


#certificates #sectionSecondary .date .button,
#activities #sectionSecondary .date .button {
    padding: 1rem;
    padding-left: 0;
    margin-right: 2rem;
    background-color: transparent;
    border: none !important;
    font-weight: 300;

}

hr {
    height: 1px;
}


@media (max-width:1024px) {

    #certificates,
    #activities {
     //  padding-top: 8rem;

        #sectionPrimary {
            .is-6 {
                padding-top: 1rem;
                padding-left: 2rem;

            }

            img {
                width: 30px;
                margin-left: 1rem;
            }
        }
    }
}


@media (max-width:550px) {
    #titleMyActivities{
        font-size: 35px !important;
    }
    #activities {
        >div:first-child{
            >div:first-child{
                display: none;
            }
            >div:last-child{
                background-color: #0080FF;
                height: 35%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 0px;
                >div{
                    >img{
                        width: 10%;
                        margin-top: 5%
                    }
                }
            }
        }

      //  position: bottom;

        #sectionPrimary {
            padding: 1rem;

            .columns {
                display: flex;
                justify-content: center;
                flex-direction: column-reverse;
                align-items: center;
            }

            .is-6 {
                padding: 8rem 1rem;
                padding-bottom: 0;
                display: flex;
                align-items: center;

            }

            .titleProp {
                margin-top: 1rem;
                text-align: center;
                font-size: 2.5rem;
            }

        }

        .titleProp span {
            padding-left: 0;
        }

        .btn-title {
            background-color: var(--gray);
            height: auto;
            padding: 1rem;
        }

        #sectionSecondary {

            .is-grouped {
                flex-direction: column;
                justify-content: center;
            }

            .button {
                margin-bottom: 20px;
            }
        }
    }

    #certificates {
        position: bottom;

        #sectionPrimary {
            padding: 1rem;

            .columns {
                display: flex;
                justify-content: center;
                flex-direction: column-reverse;

            }

            .is-6 {
                padding: 8rem 1rem;
                padding-bottom: 0;
                display: flex;
                align-items: center;

            }

            .titleProp {
                margin-top: 1rem;
                text-align: center;
                font-size: 2.5rem;
            }

        }

        .titleProp span {
            padding-left: 0;
        }

        .btn-title {
            background-color: var(--gray);
            height: auto;
            padding: 1rem;
        }

        h4 {
            text-align: center;
        }

        #sectionSecondary {
            .control {
                text-align: end;
            }
            .is-grouped {
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
            .button {
                margin-bottom: 20px;
            }
        }
    }

}