#login {
    background-image: url('../../assets/bg_login.png');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    padding-top: 8rem;
    border-radius: 0;

}

#login section .titleProp {
    font-family: 'Poppins', sans-serif;
    font-size: 4.6rem;
    line-height: 5rem;

}

#login section .titleProp img {
    margin-top: 4rem;

}

#login section form {
    font-family: NotoSans;
    padding: 3rem 5rem;

}

#login section form .input {
    border-radius: 0;
    color: var(--gray);

}

#login section form button {
    border-radius: 0;
    padding-left: 3rem;
    padding-right: 3rem;
    margin-top: 3rem;

}

#login section form .help {
    font-size: 1rem;
    text-align: center;
}

@media (max-width:768px) {
    #login {
        background-image: url('../../assets/bg_login_1-100.jpg');
        background-position: center;
        padding-top: 7rem;
    }

    #login .section {
        padding: 1rem 2.5rem;
        padding-bottom: 0;
    }

    #login section .titleProp {
        font-family: 'Poppins', sans-serif;
        font-size: 2.5rem;
        line-height: 2.3rem;


    }

    #login section .titleProp img {
        margin-top: 1rem;
        width: 25px;

    }

    #login section form {
        padding: 2rem 5rem;
        padding-top: 4rem;

    }

    #login section form .input,
    .control.has-icons-right .icon {
        height: 2rem;

    }

    #login section form button {
        margin-top: 1rem;

    }

}

@media (max-width:550px) {
    #login .section {
        padding: 1rem;
    }

    #login section form {
        padding: 1rem;
        padding-top: 4rem;

    }

    #login section form .help {
        font-size: 0.75rem;
        text-align: center;
    }
}