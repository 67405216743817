.dropbtn {
    color: white;
    border: none;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {background-color: #ddd;}
  
  .dropdown:hover .dropdown-content {display: block;}
  
#menuNav {
    a{
        text-decoration: none;
        font-size:16px;
    }
    // position: fixed;
    //  z-index: 2;
    background-color: var(--blue);

    .navbar-brand {
        img {
            max-height: unset;
            width: 55%;
        }
    }

    .navbar-menu {
        .navbar-item {
            span {
                font-family: NotoSans;
                color: var(--blue);
                border-bottom: 4px solid transparent;

                &:hover {
                    border-bottom: 4px solid var(--gray);
                    color: var(--gray);
                }
            }
        }

        #tvItem span,
        .icon-text span {
            border-bottom: none !important;

            &:hover {
                color: var(--skyBlue) !important;

            }
        }

        #userItem span,
        #userItem,
        #userItem .icon-text span {
            border-bottom: transparent !important;

            &:hover {
                background-color: var(--blue) !important;

            }

        }

        #userItem {
            margin-right: 1rem;
            
        }


        .navbar-link.is-active,
        .navbar-link:focus,
        .navbar-link:focus-within,
        .navbar-link:hover,
        a.navbar-item.is-active,
        a.navbar-item:focus,
        a.navbar-item:focus-within,
        a.navbar-item:hover {
            background-color: transparent;
        }


    }
}

@media (min-width:1024px) {

    .navbar-item {
        padding-left: 1rem;
    }
}

@media (max-width:1024px) {
    .navbar-brand {
       width: 93%;

    }
    #mobileMenu {
        position: fixed;
        width: 100%;
        height: -webkit-fill-available;
        background-color: #1c1a1ab0;
        z-index: 100;

        >div:last-child {
            background-color: #32599c;
            height: 80%;
            width: 75%;
            >div:nth-child(1){
                padding: 20px;
            }
            >div:nth-child(2){
                padding: 20px;
                >p{
                    margin-top: 5%;
                    font-weight: 600;
                    font-size: 18px;
                }
            }
        }
    }



    #menuNav {
        //  padding: 0 1rem;
        top: 1rem;
        //margin-bottom: 2rem;

        .navbar-brand {
            margin-left: 0;

            img {
                max-height: unset;
                width: 5rem;
            }

        }

        .navbar-item {
            padding-left: 0;
        }


    }
}