.modal-backdrop{
  dialog{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

}
#backdrop {
  >.modal-content{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: inherit !important;
    border: 0px !important;
  }
}


.fade.modal.show{
  display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}