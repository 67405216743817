.containerCardNext {
    display: flex;
    justify-content: space-around;
    height: 100%
}

#home {
    #listPost {
        width: 45% !important;
    }

    /* display: flex;
    flex-direction: column;*/
    .imgNextActivities {
        width: 30%
    }

    .containerDescritpionNextActivities {
        width: 45%;
    }

    .imgFeaturedActivity {
        width: 100%;
    }

    .sectionPrimary {
       // background-image: url('../../assets/bg_section_1.png');
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
        /*  height: 100vh; */
        padding-top: 2%;
        font-family: 'Poppins', sans-serif;

        .titleProp {
            font-size: 4.6rem;
        }

        .column p {
            padding-right: 15.5rem;

        }

        #float {
            position: relative;
            top: 8rem;

            .titleProp {
                font-size: 3.3rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0;
                background-color: var(--white);
                box-shadow: 0px 2px 4px 3px rgba(0, 0, 0, 0.19);
                -webkit-box-shadow: 0px 2px 4px 3px rgba(0, 0, 0, 0.19);
                -moz-box-shadow: 0px 2px 4px 3px rgba(0, 0, 0, 0.19);
                border-radius: 0;


                .text {
                    padding-left: 5rem;

                    img {
                        width: 100px;
                        margin-bottom: 11px;
                        margin-left: 1rem
                    }


                }

                .btn-title {
                    background-color: var(--gray);
                    padding: 2.5rem 3.3rem;

                    img {
                        width: 30px;
                        height: 100%;

                    }
                }

            }
        }
    }

    #btn-more .button,
    #btnPost .button {
        border-radius: 0;
        padding-left: 3rem;
        padding-right: 3rem;
        //  margin-top: 5rem;
    }

    .sectionSecondary {

        #containerPosts {

            #home {
                width: 40%;
            }


            font-family: NotoSans;
            margin-top: 6rem;
            display: flex;
            justify-content: center;

            h3 {
                font-weight: 600;
                color: var(--white);
                margin-bottom: 1rem;

            }


            #btnPost {
                display: flex;
                justify-content: space-between;

                .date {


                    .button {
                        padding: 1rem;
                        padding-left: 0;
                        margin-right: 2rem;
                        background-color: transparent;
                        border: none !important;
                        color: var(--yelow) !important;
                        margin-top: 2rem;
                    }

                }


            }

            #listPost {
                h4 {
                    font-weight: 600;
                    color: var(blue);
                    margin-bottom: 0px !important;
                }

                .date .button {
                    padding: 1rem;
                    padding-left: 0;
                    margin-right: 2rem;
                    background-color: transparent;
                    border: none !important;
                    font-weight: 300;

                }
            }

            #btnPost .date i,
            #listPost .date i {
                margin-right: 8px;
                font-size: 1.3rem;
            }
        }

        hr {
            height: 1px;
        }

    }

    /*   Tablet y Laptop*/

    @media (max-width:1024px) {

        .sectionPrimary {
            padding-top: 10rem;


            .titleProp {
                font-size: 2.6rem;
                line-height: 2.5rem;
                margin-bottom: 20px;
            }

            .column p {
                padding-right: 4rem;
            }

            #float {

                position: inherit;
                top: 0rem;

                .titleProp {
                    font-size: 2rem;
                    line-height: 2.5rem;

                    .btn-title {
                        padding: 2rem;

                        img {
                            width: 60px;
                        }
                    }
                }
            }

        }


        .sectionSecondary {
            #containerPosts {
                margin-top: 0;
            }

            #btnPost .date {
                display: flex;
                justify-content: space-between;
            }

            #btnPost .date .button {
                margin-right: 0px;
                display: flex;
                justify-content: space-between;
            }
        }




    }

    /* 
Mobile */
    @media (max-width:667px) {
        .imgNextActivities {
            width: 40%
        }
        #containerPosts {
            flex-direction: column;

        }

        .sectionSecondary {
            #containerPosts {
                .date {
                    width: 100% !important;
                }

                #home {
                    width: 100% !important;
                }
            }
        }

        .sectionSecondary {
            #containerPosts {
                #listPost {
                    width: 100% !important;

                    .containerCardNext {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;

                    }
                }
            }
        }




        .section {
            padding-top: 0;
            padding-bottom: 2rem;
        }

        .sectionPrimary {
            padding-top: 6rem;
            margin-bottom: 1rem;
            text-align: center;
            background-position: left;

            >div:first-child {
                margin-top: 0px !important;
            }


            .titleProp {
                font-size: 2rem;
                line-height: 2rem;
            }

            .column p {
                padding-right: 0rem;

            }

            #btn-more .button {
                border-radius: 0;
                padding-left: 1rem;
                padding-right: 1rem;
                margin-top: 1.5rem;

            }

            #float {

                .titleProp {
                    font-size: 1.3rem;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-radius: 0;
                    padding: 0;
                    line-height: 2rem;
                    top: -20px;

                    span {
                        padding-left: 0;
                    }

                    .btn-title {
                        background-color: var(--gray);
                        height: auto;
                        padding: 1rem;

                        img {
                            width: 20px;
                        }
                    }

                    .text {
                        padding-left: 1rem;

                        img {
                            width: 46px;
                            margin-bottom: 4px;
                        }
                    }
                }
            }
        }

        .sectionSecondary {
            #containerPosts .post {
                text-align: center;


                #btnPost {

                    flex-direction: column;
                    justify-content: center;

                    .date {
                        justify-content: space-around;

                        .button {
                            margin-right: 0;
                        }
                    }

                    .btn-inscribirse .button {
                        margin-top: 1rem;
                    }


                }

                .field.is-grouped>.control:not(:last-child) {

                    margin-right: 0;
                }

                #listPost {

                    .subtitle {
                        text-align: center;
                    }

                    .date .button {
                        padding: 0;
                        font-size: 0.75rem;

                    }
                }

            }
        }

    }
 
}

.pop-up {
    top:0px;
    position: fixed;
    z-index: 1055;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
}

.pop-up-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.pop_up_mobile {
    display: none;
}

.pop-up_desktop {
    display: block;
}
//hide pop_up_desktop in mobile devices
@media (max-width: 767px) {
    .pop-up_desktop {
        display: none;
    }
    .pop_up_mobile {
        display: flex;
    }
    .pop-up-content {
        top: 50%;
        left: 30%;
        transform: translate(-23%, -50%);
    }
}
