/* ######################### Register & Porfile ############################## */

#register,
#profile {

    section .titleProp {
        font-family: 'Poppins', sans-serif;
        font-size: 4rem;
        line-height: 5rem;

        .titleProp img {
            margin-top: 2rem;
        }

    }

    form {

        .columns {
            font-family: NotoSans;
            padding: 0 3rem;
            /*  margin-bottom: 8rem; */

        }

        .input {
            border-radius: 0;
            color: var(--gray);
        }

        .radio input {
            cursor: pointer;
            margin-right: 10px;

        }

        & ::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: var(--gray);
            font-family: NotoSans;
            font-family: NotoSans;
            opacity: 1;
            /* Firefox */
        }

        &:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: var(--gray);
            font-family: NotoSans;
            opacity: 1;
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: var(--gray);
            font-family: NotoSans;
        }

        &:-ms-input-placeholder {
            /* Microsoft Edge */
            color: var(--gray);
            font-family: NotoSans;
        }

        button {
            border-radius: 0;
            border: none;
            padding-left: 3rem;
            padding-right: 3rem;
            margin-top: 3rem;
            background: rgb(2, 88, 160);
            background: linear-gradient(90deg, rgba(2, 88, 160, 1) 0%, rgba(0, 144, 255, 1) 55%);
            color: #ffffff;
        }

    }


    @media (max-width:768px) {
        .section {
            padding: 1rem 1.5rem;
            padding-bottom: 0;


            .titleProp {
                font-family: 'Poppins', sans-serif;
                font-size: 4rem;
                line-height: 4rem;
                text-align: center;

                img {
                    margin-top: 1rem;
                    width: 25px;

                }
            }

            form {
                padding: 1rem;
                padding-top: 4rem;

                button {
                    margin-top: 1rem;

                }

                .columns {
                    padding: 0;
                    /*  margin-bottom: 8rem; */

                }
            }
        }

    }

}

/* ######################### Register ############################## */
#register {
    background-image: url('../../assets/bg_register_desktop.png');
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 2%;
    border-radius: 0;

    .section {
        padding-bottom: 0;

        .titleProp {

            font-size: 3rem;
        }
    }

    form {
        .fot-register {
            padding-top: 3rem;
            margin-top: 5em;

            @media (max-width:555px) {
                & {
                    padding-top: 1rem;
                    margin-top: 4rem;
                }
            }

            .button {
                margin-bottom: 1rem;
            }

        }
    }

    @media (max-width:768px) {

        & {
            background-image: url('../../assets/bg_mobile_register.png');
            padding-top: 7rem;
            background-position: top;
        }

    }

    @media (max-width:555px) {

        padding-top: 5rem;

        .section {
            form {
                margin: 0 !important;
                padding-top: 0;
            }

            .fot-register {
                padding: 0;

                .control {
                    font-size: 0.75rem;
                    margin-bottom: 0.5rem;
                }


            }
        }
    }



}

/* ######################### Porfile ############################## */

#profile {
    background-image: url('../../assets/bg_porfile_desktop.png');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 10rem;
    border-radius: 0;

    form {
        .fot-register {
            padding-top: 4rem;
            margin-top: 8rem;

            @media (max-width:768px) {

                & {
                    padding-top: 1rem;
                    margin-top: 2rem;
                }

            }

        }

        .header-form {
            padding-bottom: 1rem;
            margin-top: 1rem;
            color: var(--white) !important;

        }
    }

    @media (max-width:768px) {
        & {
            background-image: url('../../assets/bg_porfile_mobile_5.png');
            background-position: top;
            padding-top: 10rem;
        }

    }

    @media (max-width:555px) {

        padding-top: 5rem;

        .section {
            form {
                margin: 0 !important;
                padding-top: 0;
            }

            .header-form {
                padding-bottom: 3rem;

                .control {
                    font-size: 0.75rem;
                }


            }
        }
    }


}


/* 
##################################################################################################
################################################################################################## 

*/


/* @media (max-width:555px) {

}

    #register section form,
    #profile section form {
        padding: 1rem;
        padding-top: 4rem;

    }

    #register section form .help,
    #profile section form .help {
        font-size: 0.75rem;
        text-align: center;
    }

    #register section form .fot-register,
    #profile section form .fot-register {
        padding-bottom: 6rem;
        margin-top: 6rem;
    }

    #register section form,
    #profile section form .columns {
        margin-bottom: 2rem;

    }

    #profile section form .header-form {
        padding: 1rem 0;
        color: var(--white) !important;

    }

} */